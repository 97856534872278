

export const data = [
    {
        id: 1,
        h5: "MENU",
        p: "ABOUT\n" +
        "SERVICES\n" +
        "PORTFOLIO\n" +
        "CONTACT"
    },
    {
        id: 2,
        h5: "ADDRESS",
        p: "Suite #913\n" +
        "Corporation Building\n" +
        "Tibb Street\n" +
        "Manchester\n" + 
        "M1 1TA"
    },
    {
        id: 3,
        h5: "CONTACT",
        p: "E: info(at)youragency.com\n" +
        "T: +44 800 123 456\n" +
        "F: +44 800 123 400"
    },
]